import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import { BiPhoneCall } from 'react-icons/bi'
import { TbCalendarTime } from 'react-icons/tb'



const HeaderTwo = () => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute right-[3%] left-[3%] z-[15] mt-5'>
            <div className='md:flex block'>
                <div className='w-[100%] mr-0 md:w-[100%] lg:w-[25%] lg:mr-10'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[80%] mx-auto pt-3 pb-5'
                    />
                </div>

                <div className=' md:block w-[100%] rounded-tr-[20px] rounded-br-[20px] px-5 pt-6 pb-3 lg:w-[75%] self-center bg-[#6ca40593]'>
                    <div className='md:flex hidden'>
                        <div className='w-[55%] lg:w-[75%]'>
                            <div className='grid grid-cols-1 lg:grid-cols-3'>

                                <div className='flex  text-white'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BiPhoneCall color='white' fontSize={40} />
                                    </div>
                                    <div>
                                        <a
                                            href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                        >
                                            <p className='-mb-3'>Phone Number</p>
                                            <h5 className='text-[21px]'>
                                                {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                            </h5>
                                        </a>
                                    </div>
                                </div>
                                <div className='flex  text-white'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BiPhoneCall color='white' fontSize={40} />
                                    </div>
                                    <div>
                                        <a
                                            href={`tel:+1${rpdata?.dbPrincipal?.phones?.[1]?.phone}`}
                                        >
                                            <p className='-mb-3'>Phone Number</p>
                                            <h5 className='text-[21px]'>
                                                {rpdata?.dbPrincipal?.phones?.[1]?.phone}
                                            </h5>
                                        </a>
                                    </div>
                                </div>


                                <div className='hidden lg:flex justify-end text-white'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <TbCalendarTime color='white' fontSize={40} />
                                    </div>
                                    <div>
                                        <p className='-mb-3'>{rpdata?.dbPrincipal?.workdays?.[0]?.day}</p>
                                        <h5 className='text-[21px]'>
                                            {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-[45%] lg:w-[25%] text-end border-l ml-0 lg:ml-7'>
                            <ButtonContent btnphone={rpdata?.dbPrincipal?.phones[0]?.phone} />
                        </div>
                    </div>
                </div>


            </div>


        </header>
    )
}

export default HeaderTwo